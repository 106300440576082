<template>
  <modal
    name="trabalheConoscoCreate"
    height="auto"
    width="900"
    :scrollable="true"
    :clickToClose="false"
  >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="`Criando ${descSingular}`">
            <div class="xrow">
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Título</label>
                  <input class="form-control" v-model="item.titulo" />
                  <div class="message">
                    {{ validation.firstError("item.titulo") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Ativo</label>
                  <select class="form-control" v-model="item.ativo">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                  <div class="message">
                    {{ validation.firstError("item.ativo") }}
                  </div>
                </div>
              </div>
              <div class="col-1-4">
                <div class="form-group">
                  <label for="">Ordem</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="item.ordem"
                  />
                  <div class="message">
                    {{ validation.firstError("item.ordem") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Data Publicação</label>
                  <datetime
                    inputId="datahora_publicacao"
                    input-class="form-control"
                    type="datetime"
                    v-model="item.datahora_publicacao"
                    :phrases="{ ok: 'Confirmar', cancel: 'Fechar' }"
                    value-zone="local"
                  />
                  <div class="message">
                    {{ validation.firstError("item.datahora_publicacao") }}
                  </div>
                </div>
              </div>
              <div class="col-1-1">
                <div class="form-group">
                  <label for="">Descrição</label>
                  <textarea class="form-control" v-model="item.descricao" />
                  <div class="message">
                    {{ validation.firstError("item.descricao") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3 add-image">
                <label>
                  Adicionar Imagem
                  <image-picker
                    label="Imagem Principal"
                    placeholder="Colar URL ou clique ao lado para upload"
                    v-model="item.imagem_principal"
                  />
                </label>
                <div class="message">
                  {{ validation.firstError("item.imagem_principal") }}
                </div>
              </div>
              <div class="col-2-3">
                <div class="form-group">
                  <label for="">Meta Título</label>
                  <input class="form-control" v-model="item.meta_title_tag" />
                  <div class="message">
                    {{ validation.firstError("item.meta_title_tag") }}
                  </div>
                </div>
              </div>
              <div class="col-1-1">
                <div class="form-group">
                  <label for="">Meta Descrição</label>
                  <textarea
                    class="form-control"
                    v-model="item.meta_description"
                    height="auto"
                  />
                  <div class="message">
                    {{ validation.firstError("item.meta_description") }}
                  </div>
                </div>
              </div>
              <div class="col-1-1">
                <div class="form-group">
                  <editor-conteudo :item="this.item"></editor-conteudo>

                  <div class="message">
                    {{ validation.firstError("item.conteudo") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                :class="{
                  'button button-success': true,
                  'button-disabled': validation.hasError(),
                }"
                v-on:click="handleSubmit"
              >
                Inserir
              </button>
              <button class="button" v-on:click="close">Fechar</button>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>
<script>
import { Validator } from "simple-vue-validator";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import CloseModal from "../../components/CloseModal";
import { VueEditor } from "vue2-editor";
import ImagePicker from "../../components/ImagePicker";
import EditorConteudo from "../../components/EditorConteudo";

const initial = () => ({
  titulo: "",
  descricao: "",
  ativo: 1,
});

export default {
  name: "trabalheConoscoCreateA",
  props: ["access", "create", "close", "modulos", "descSingular"],
  components: { CloseModal, VueEditor, ImagePicker, EditorConteudo },
  data() {
    return {
      item: initial(),
      loadingModulos: [],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link", "image", "video", "code"],
        ["clean"],
      ],
    };
  },
  validators: {
    "item.titulo": (value) => Validator.value(value).required(),
    "item.ativo": (value) => Validator.value(value).required(),
    "item.descricao": (value) => Validator.value(value).required(),
    "item.ordem": (value) => Validator.value(value).required(),
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          const item = this.item;
          this.create(item);
        }
      });
    },
  },
};
</script>
